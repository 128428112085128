.Products {
    .infos {
        background-color: #e7effa;

        @media (max-width:767px) {
            gap: 4vw;
            grid-template-columns: repeat(1, 1fr);
        }

        .mySwiper {
            width: 100%;

            .name {
                height: 17vw;
                display: flex;
                font-weight: 600;
                padding-left: 4vw;
                align-items: center;
                text-transform: uppercase;

                @media (max-width:767px) {
                    height: 45vw;
                }
            }

            .info {
                height: 17vw;
                display: flex;
                padding: 0 3vw;
                position: relative;
                align-items: center;
                justify-content: center;

                &::before {
                    top: 0;
                    left: 0;
                    content: "";
                    height: 100%;
                    width: 150vw;
                    position: absolute;
                    background-color: #1364ce1d;
                    border-top-left-radius: 168px;
                    border-bottom-left-radius: 168px;

                    @media (max-width:767px) {
                        width: 400vw;
                        border-top-left-radius: 100px;
                        border-bottom-left-radius: 100px;
                    }
                }

                @media (max-width:767px) {
                    width: 100%;
                    height: 45vw;
                    padding: 3vw;
                    padding-left: 10vw;
                }

                .text {
                    z-index: 2;
                    font-weight: 600;
                    position: sticky;

                    @media (max-width:767px) {
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
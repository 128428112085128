.Prices {
    .wrapper {
        display: flex;
        align-items: center;
        padding-bottom: 10vw;
        justify-content: space-between;

        @media (max-width:767px) {
            gap: 8vw;
            flex-direction: column;
        }

        .left {
            width: 28%;
            display: flex;
            padding: 5vw 4vw;
            position: relative;
            align-items: center;
            border-radius: 228px;
            -webkit-border-radius: 228px;
            -moz-border-radius: 228px;
            -ms-border-radius: 228px;
            -o-border-radius: 228px;
            flex-direction: column;
            background-color: #1365ce;

            &::before {
                left: -25%;
                content: "";
                width: 100%;
                z-index: -1;
                height: 100%;
                position: absolute;
                border-radius: 228px;
                -webkit-border-radius: 228px;
                -moz-border-radius: 228px;
                -ms-border-radius: 228px;
                -o-border-radius: 228px;
                background-color: #1364ce24;

                @media (max-width:767px) {
                    right: -4%;
                    left: auto;
                    bottom: -4%;
                    border-radius: 4vw;
                    -webkit-border-radius: 4vw;
                    -moz-border-radius: 4vw;
                    -ms-border-radius: 4vw;
                    -o-border-radius: 4vw;
                }
            }

            @media (max-width:767px) {
                width: 100%;
                border-radius: 4vw;
                border-radius: 4vw;
                -webkit-border-radius: 4vw;
                -moz-border-radius: 4vw;
                -ms-border-radius: 4vw;
                -o-border-radius: 4vw;
            }

            .name {
                color: white;
                font-weight: 600;
                text-transform: uppercase;
            }

            .tools {
                display: flex;
                flex-direction: column;
            }

            .tool {
                display: flex;
                align-items: center;

                .icon {
                    max-width: 3.3vw;
                    min-width: 3.3vw;

                    @media (max-width:767px) {
                        max-width: 9vw;
                        min-width: 9vw;
                    }
                }

                .text {
                    color: #fefefe;
                    font-weight: 500;
                }
            }
        }

        .right {
            width: 60%;
            display: grid;
            align-items: flex-start;
            grid-template-columns: repeat(2, 2fr);

            @media (max-width:767px) {
                gap: 7vw;
                width: 100%;
                margin-top: 5vw;
                grid-template-columns: repeat(1, 1fr);
            }

            .sub-title {
                font-weight: 600;
                text-transform: uppercase;
            }

            .info {
                display: flex;
                flex-direction: column;

                .range {
                    width: 5vw;
                    height: 0.7vw;
                    display: flex;
                    border-radius: 70px;
                    -webkit-border-radius: 70px;
                    -moz-border-radius: 70px;
                    -ms-border-radius: 70px;
                    -o-border-radius: 70px;
                    background-color: #ff7600;

                    @media (max-width:767px) {
                        width: 15vw;
                        height: 1.5vw;
                    }
                }

                .range_blue {
                    background-color: #1365ce;
                }

                .big-text {
                    font-weight: 600;
                }

                .texts {
                    gap: 0.7vw;
                    display: flex;
                    flex-direction: column;
                }
            }

            .contact_btn {
                border: none;
                display: flex;
                color: white;
                cursor: pointer;
                font-weight: 800;
                transition: 0.2s;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
                padding: 1vw 2.2vw;
                position: relative;
                align-items: center;
                border-radius: 70px;
                -webkit-border-radius: 70px;
                -moz-border-radius: 70px;
                -ms-border-radius: 70px;
                -o-border-radius: 70px;
                text-decoration: none;
                justify-content: center;
                background-color: #1D65D5;

                &::before {
                    top: 0;
                    right: -22%;
                    z-index: -2;
                    content: "";
                    width: 100%;
                    height: 100%;
                    transition: 0.2s;
                    -webkit-transition: 0.2s;
                    -moz-transition: 0.2s;
                    -ms-transition: 0.2s;
                    -o-transition: 0.2s;
                    position: absolute;
                    border-radius: 70px;
                    -webkit-border-radius: 70px;
                    -moz-border-radius: 70px;
                    -ms-border-radius: 70px;
                    -o-border-radius: 70px;
                    background-color: #E5EFFA;

                    @media (max-width:767px) {
                        display: none;
                    }
                }

                &::after {
                    top: 0;
                    right: -7%;
                    z-index: -1;
                    content: "";
                    width: 100%;
                    height: 100%;
                    transition: 0.2s;
                    -webkit-transition: 0.2s;
                    -moz-transition: 0.2s;
                    -ms-transition: 0.2s;
                    -o-transition: 0.2s;
                    position: absolute;
                    border-radius: 70px;
                    -webkit-border-radius: 70px;
                    -moz-border-radius: 70px;
                    -ms-border-radius: 70px;
                    -o-border-radius: 70px;
                    background-color: #B7D3F3;

                    @media (max-width:767px) {
                        display: none;
                    }
                }

                &:hover {

                    &::before,
                    &::after {
                        right: 0;
                    }
                }

                @media (max-width:767px) {
                    padding: 3vw;
                    margin-top: 0;
                }
            }

            .ver_1 {
                @media (max-width:767px) {
                    display: none;
                }
            }

            .ver_2 {
                @media (min-width:767.1px) {
                    display: none;
                }
            }
        }
    }
}
.Header {
    .wrapper {
        display: flex;
        min-height: 100vh;
        position: relative;
        align-items: center;
        background-color: #1D65D5;

        @media (max-width:767px) {
            min-height: 500px;
        }

        .circle_1 {
            top: -43vh;
            right: 8vw;
            width: 150vw;
            height: 150vh;
            position: absolute;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            background-color: #E5EFFA;

            @media (max-width:767px) {
                top: -60vw;
                width: 400vw;
                height: 230vw;
            }
        }

        .circle_2 {
            top: -34vh;
            right: 23vw;
            width: 150vw;
            height: 150vh;
            position: absolute;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            background-color: white;

            @media (max-width:767px) {
                top: -50vw;
                width: 400vw;
                height: 230vw;
            }
        }

        .img {
            z-index: 2;
            right: 7vw;
            bottom: 7vw;
            width: 45vw;
            position: absolute;

            @media (max-width:767px) {
                display: none;
            }
        }

        .texts {
            width: 50%;
            z-index: 3;
            display: flex;
            position: sticky;
            flex-direction: column;
            align-items: flex-start;

            @media (max-width:767px) {
                width: 100%;
            }

            .title {
                font-size: 4.6vw;
                font-weight: 900;

                @media (max-width:767px) {
                    font-size: 11vw;
                }

                span {
                    color: #FA6C00;
                    font-weight: 700;
                }
            }

            .contact_btn {
                border: none;
                color: white;
                cursor: pointer;
                font-weight: 800;
                transition: 0.2s;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
                padding: 1vw 2.2vw;
                position: relative;
                border-radius: 70px;
                -webkit-border-radius: 70px;
                -moz-border-radius: 70px;
                -ms-border-radius: 70px;
                -o-border-radius: 70px;
                text-decoration: none;
                background-color: #1D65D5;

                &::before {
                    top: 0;
                    right: -22%;
                    z-index: -2;
                    content: "";
                    width: 100%;
                    height: 100%;
                    transition: 0.2s;
                    -webkit-transition: 0.2s;
                    -moz-transition: 0.2s;
                    -ms-transition: 0.2s;
                    -o-transition: 0.2s;
                    position: absolute;
                    border-radius: 70px;
                    -webkit-border-radius: 70px;
                    -moz-border-radius: 70px;
                    -ms-border-radius: 70px;
                    -o-border-radius: 70px;
                    background-color: #E5EFFA;
                }

                &::after {
                    top: 0;
                    right: -7%;
                    z-index: -1;
                    content: "";
                    width: 100%;
                    height: 100%;
                    transition: 0.2s;
                    -webkit-transition: 0.2s;
                    -moz-transition: 0.2s;
                    -ms-transition: 0.2s;
                    -o-transition: 0.2s;
                    position: absolute;
                    border-radius: 70px;
                    -webkit-border-radius: 70px;
                    -moz-border-radius: 70px;
                    -ms-border-radius: 70px;
                    -o-border-radius: 70px;
                    background-color: #B7D3F3;
                }

                &:hover {
                    padding: 1vw 3.5vw;

                    &::before,
                    &::after {
                        right: 0;
                    }
                }

                @media (max-width:767px) {
                    padding: 3vw 9vw;

                    &:hover {
                        padding: 3vw 9vw;
                    }
                }
            }
        }
    }
}
.About {
    .wrapper {
        display: flex;
        padding-bottom: 0;
        align-items: center;

        @media (max-width:767px) {
            padding-top: 6vw;
            flex-direction: column;
            align-items: flex-start;
        }

        .top {
            display: flex;
            align-items: flex-end;
        }

        .logo {
            width: 11vw;

            @media (max-width:767px) {
                width: 27vw;
            }

            .img {
                max-width: 100%;
                min-width: 100%;
            }
        }

        .texts {
            display: flex;
            flex-direction: column;
        }

        .sub-title {
            font-weight: 600;
        }
    }

    .ver-1 {
        @media (max-width:767px) {
            display: none;
        }
    }

    .ver-2 {
        @media (min-width:767.1px) {
            display: none;
        }
    }
}
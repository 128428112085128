.Vacancies {
    .wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .sub-title {
            font-weight: 600;
            text-align: center;
        }

        .contact_btn {
            border: none;
            display: flex;
            color: white;
            cursor: pointer;
            font-weight: 500;
            padding: 1vw 6vw;
            transition: 0.2s;
            -webkit-transition: 0.2s;
            -moz-transition: 0.2s;
            -ms-transition: 0.2s;
            -o-transition: 0.2s;
            position: relative;
            align-items: center;
            border-radius: 70px;
            -webkit-border-radius: 70px;
            -moz-border-radius: 70px;
            -ms-border-radius: 70px;
            -o-border-radius: 70px;
            justify-content: center;
            background-color: #1D65D5;

            &::before {
                top: 0;
                right: -22%;
                z-index: -2;
                content: "";
                width: 100%;
                height: 100%;
                transition: 0.2s;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
                position: absolute;
                border-radius: 70px;
                -webkit-border-radius: 70px;
                -moz-border-radius: 70px;
                -ms-border-radius: 70px;
                -o-border-radius: 70px;
                background-color: #E5EFFA;
            }

            &::after {
                top: 0;
                right: -7%;
                z-index: -1;
                content: "";
                width: 100%;
                height: 100%;
                transition: 0.2s;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
                position: absolute;
                border-radius: 70px;
                -webkit-border-radius: 70px;
                -moz-border-radius: 70px;
                -ms-border-radius: 70px;
                -o-border-radius: 70px;
                background-color: #B7D3F3;
            }

            &:hover {
                padding: 1vw 9vw;

                &::before,
                &::after {
                    right: 0;
                }
            }

            @media (max-width:767px) {
                padding: 2.5vw 15vw;

                &:hover {
                    padding: 2.5vw 15vw;
                }
            }
        }
    }
}
.OurClients {
    .wrapper {
        gap: 12vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background-color: white;

        @media (max-width:767px) {
            gap: 0;
            justify-content: space-between;
        }

        .left {
            width: 20%;
            display: flex;
            align-items: center;
            flex-direction: column;

            @media (max-width:767px) {
                width: 40%;
            }

            .big-text {
                font-weight: 600;
            }

            .client {
                width: 100%;
                height: 6vw;
                display: flex;
                align-items: center;
                border-radius: 70px;
                -webkit-border-radius: 70px;
                -moz-border-radius: 70px;
                -ms-border-radius: 70px;
                -o-border-radius: 70px;
                text-decoration: none;
                justify-content: center;
                background-color: #b5bcc424;

                &:hover {
                    background-color: #4e9aff;

                    .ver_1 {
                        display: none;
                    }

                    .ver_2 {
                        display: flex;
                    }
                }

                @media (max-width:767px) {
                    height: 12vw;
                }

                .img {
                    max-width: 65%;
                    min-width: 65%;
                    max-height: 65%;
                    min-height: 65%;
                    object-fit: contain;
                }

                .ver_2 {
                    display: none;
                }
            }
        }

        .right {
            width: 20%;
            display: flex;
            align-items: flex-end;
            flex-direction: column;

            @media (max-width:767px) {
                width: 50%;
            }

            .support {
                max-width: 5vw;
                min-width: 5vw;

                @media (max-width:767px) {
                    max-width: 10vw;
                    min-width: 10vw;
                }
            }

            .text {
                font-weight: 600;
                line-height: 1vw;
                text-align: right;

                @media (max-width:767px) {
                    line-height: 3.5vw;
                }
            }

            .name {
                display: flex;
                color: black;
                font-weight: 600;
                align-items: center;
                justify-content: flex-end;

                .icon {
                    max-width: 3vw;
                    min-width: 3vw;

                    @media (max-width:767px) {
                        max-width: 6vw;
                        min-width: 6vw;
                    }
                }
            }
        }

        .clients {
            display: grid;
            grid-template-columns: repeat(2, 2fr);

            .client {
                width: 100%;
                height: 6vw;
                display: flex;
                align-items: center;
                border-radius: 70px;
                -webkit-border-radius: 70px;
                -moz-border-radius: 70px;
                -ms-border-radius: 70px;
                -o-border-radius: 70px;
                text-decoration: none;
                justify-content: center;
                background-color: #b5bcc424;

                &:hover {
                    background-color: #4e9aff;

                    .ver_1 {
                        display: none;
                    }

                    .ver_2 {
                        display: flex;
                    }
                }

                @media (max-width:767px) {
                    height: 12vw;
                }

                .img {
                    max-width: 65%;
                    min-width: 65%;
                    max-height: 65%;
                    min-height: 65%;
                    object-fit: contain;
                }
            }
        }
    }
}
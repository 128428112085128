.Modal {
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;

    .child,
    .child_2 {
        z-index: 10;
        width: 50vw;
        display: flex;
        overflow: auto;
        max-height: 70vh;
        position: absolute;
        flex-direction: column;
        background-color: white;

        @media (max-width:767px) {
            width: 90vw;
        }

        .close_btn {
            top: 1vw;
            right: 1vw;
            border: none;
            display: flex;
            cursor: pointer;
            position: absolute;
            background-color: #0d2261;

            .icon {
                max-width: 1vw;
                min-width: 1vw;

                @media (max-width:767px) {
                    max-width: 3vw;
                    min-width: 3vw;
                }
            }
        }

        .name,
        .sub-title {
            font-weight: 700;
            text-align: center;
        }

        .texts {
            display: flex;
            flex-direction: column;

            .text {
                opacity: 0.7;
                display: flex;
                padding-left: 1vw;
                position: relative;
                align-items: center;

                @media (max-width:767px) {
                    padding-left: 2.5vw;
                }

                &::before {
                    left: 0;
                    content: "";
                    opacity: 0.6;
                    width: 0.5vw;
                    height: 0.5vw;
                    position: absolute;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    background-color: #000000;

                    @media (max-width:767px) {
                        width: 1.5vw;
                        height: 1.5vw;
                    }
                }
            }
        }
    }

    .child_2 {
        width: 30vw;

        @media (max-width:767px) {
            width: 75vw;
        }
    }
}
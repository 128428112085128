.Contacts {
    .wrapper {
        display: flex;
        position: relative;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        @media (max-width:767px) {
            padding-top: 7vw;
            padding-bottom: 7vw;
        }

        &::before {
            top: 0;
            left: 0;
            z-index: 1;
            width: 90%;
            content: "";
            height: 100%;
            position: absolute;
            background-color: #1364ce15;
            border-top-right-radius: 455px;
            border-bottom-right-radius: 455px;

            @media (max-width:767px) {
                width: 100%;
            }
        }

        &::after {
            top: 0;
            z-index: 0;
            right: -15%;
            content: "";
            height: 100%;
            aspect-ratio: 1/1;
            position: absolute;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            background-color: #b8d0f0;

            @media (max-width:767px) {
                right: -70%;
            }
        }

        .sub-title {
            z-index: 2;
            font-weight: 600;
            position: sticky;
            text-align: center;
        }

        .forms {
            z-index: 2;
            width: 70%;
            display: grid;
            position: sticky;
            grid-template-columns: repeat(2, 2fr);

            @media (max-width:767px) {
                width: 90%;
                grid-template-columns: repeat(1, 1fr);
            }

            .input {
                outline: none;
                padding: 1.2vw 2vw;
                border-radius: 70px;
                -webkit-border-radius: 70px;
                -moz-border-radius: 70px;
                -ms-border-radius: 70px;
                -o-border-radius: 70px;
                border: solid 1px transparent;
                background-color: #ffffffa3;

                &:focus {
                    border-color: black;
                }

                @media (max-width:767px) {
                    padding: 2.5vw 5vw;
                }
            }

            .btns {
                width: 200%;
                display: flex;
                justify-content: center;

                @media (max-width:767px) {
                    width: 100%;
                }

                .send_btn {
                    border: none;
                    color: white;
                    font-weight: 600;
                    padding: 1.2vw 6vw;
                    border-radius: 70px;
                    -webkit-border-radius: 70px;
                    -moz-border-radius: 70px;
                    -ms-border-radius: 70px;
                    -o-border-radius: 70px;
                    background-color: #ff7600;

                    @media (max-width:767px) {
                        padding: 2.5vw 13vw;
                    }
                }
            }
        }
    }
}
.Navbar {
    top: 0;
    left: 0;
    z-index: 5;
    width: 100vw;
    display: flex;
    position: fixed;
    padding: 2vw 5vw;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    align-items: center;
    justify-content: space-between;

    @media (max-width:767px) {
        padding: 3vw 5vw;
    }

    .logo {
        width: 8vw;

        @media (max-width:767px) {
            width: 22vw;
        }

        .img {
            max-width: 100%;
            min-width: 100%;
        }
    }

    .links {
        display: flex;
        align-items: center;

        @media (max-width:767px) {
            top: 0;
            width: 100vw;
            right: -100vw;
            height: 100vh;
            padding: 20vw 0;
            transition: 0.3s;
            -webkit-transition: 0.3s;
            -moz-transition: 0.3s;
            -ms-transition: 0.3s;
            -o-transition: 0.3s;
            position: absolute;
            flex-direction: column;
            background-color: #0d2261;
            justify-content: space-between;
        }

        .link {
            border: none;
            display: flex;
            cursor: pointer;
            color: #0d2261;
            text-align: center;
            position: relative;
            text-decoration: none;
            justify-content: center;
            background-color: transparent;

            &::before {
                width: 0%;
                height: 2px;
                content: "";
                bottom: -2px;
                transition: 0.2s;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
                position: absolute;
                border-radius: 10px;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                -ms-border-radius: 10px;
                -o-border-radius: 10px;
                background-color: #0d2261;
            }

            &:hover {
                &::before {
                    width: 100%;
                }
            }

            @media (max-width:767px) {
                color: white;
                font-size: 18px;
            }
        }

        .language {
            display: flex;
            position: relative;
            justify-content: center;

            .lang_title {
                border: none;
                cursor: pointer;
                color: #0d2261;
                font-weight: 600;
                padding: 0.3vw 0.7vw;
                background-color: #ffffff7d;
                border: solid 1px transparent;

                &:hover {
                    color: white;
                    background-color: #0d2261;
                }

                @media (max-width:767px) {
                    font-size: 18px;
                    padding: 1vw 4vw;
                    background-color: white;

                    &:hover {
                        color: #0d2261;
                        background-color: white;
                    }
                }
            }

            .lang_menu {
                top: 2.5vw;
                z-index: 7;
                width: 100%;
                display: flex;
                overflow: hidden;
                position: absolute;
                flex-direction: column;
                border: solid 1px #0d2261;

                @media (max-width:767px) {
                    top: 11vw;
                    border-color: white;
                }

                .lang {
                    border: none;
                    padding: 2px 0;
                    cursor: pointer;
                    color: #0d2261;
                    background-color: white;

                    &:hover {
                        color: white;
                        background-color: #0d2261;
                    }

                    @media (max-width:767px) {
                        font-size: 18px;
                    }
                }
            }
        }

        .close_btn {
            top: 4vw;
            right: 4vw;
            border: none;
            position: absolute;
            background-color: transparent;

            @media (min-width:767.1px) {
                display: none;
            }

            .icon {
                max-width: 5vw;
                min-width: 5vw;
            }
        }
    }

    .show_links {
        right: 0;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -o-transition: 0.3s;
    }

    .tel_btn {
        display: flex;
        color: black;
        font-weight: 600;
        padding: 0.8vw 1.5vw;
        align-items: center;
        border-radius: 70px;
        -webkit-border-radius: 70px;
        -moz-border-radius: 70px;
        -ms-border-radius: 70px;
        -o-border-radius: 70px;
        text-decoration: none;
        background-color: #ffffff7d;
        border: solid 1px transparent;

        @media (max-width:767px) {
            padding: 1.5vw 4vw;
        }

        .icon {
            max-width: 1.5vw;
            min-width: 1.5vw;

            @media (max-width:767px) {
                max-width: 4vw;
                min-width: 4vw;
            }
        }
    }

    .menu_btn {
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border: solid 1px transparent;

        @media (min-width:767.1px) {
            display: none;
        }

        .icon {
            max-width: 5vw;
            min-width: 5vw;
        }
    }
}

.ScrollingNavbar {
    padding: 0.5vw 5vw;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    background-color: white;
    box-shadow: 0 0 3px 2px #0d22616f;

    @media (max-width:767px) {
        padding: 1vw 5vw;
    }

    .tel_btn {
        border-color: #0d22616f;
    }

    .links {
        .language {
            .lang_title {
                border-color: #0d22616f;
            }
        }
    }

    .menu_btn {
        border-color: #0d22616f;
    }
}